import "./Sidebar.css";

import { SidebarHeader } from "./SidebarHeader";
import { SidebarFooter, SidebarFooterLogo } from "./SidebarFooter";
import { UserKnowledgeDomain } from "./SidebarUserKD";
import { AddKnowledgeDomain } from "./SidebarAddKD";
import { useUserAuth } from "../../utils/firebaseUtils";
import { useEffect, useState } from "react";
import { getUserSubscriptions } from "../../utils/firebaseUtils";

export function Sidebar(props) {
  const { user } = useUserAuth();
  const [subscription, setsubscription] = useState(null);
  useEffect( () => {
    getUserSubscriptions(user.uid, setsubscription);
  }, [user]);


  
  return (
    <div id="sidebar">
      <SidebarHeader subscription={subscription} uid={user.uid} setLoading={props.setModals} modals={props.modals} />

      <UserKnowledgeDomain />

      <AddKnowledgeDomain setModals={props.setModals} modals={props.modals}/>

      <SidebarFooter subscription={subscription} setModals={props.setModals} modals={props.modals} />

      <SidebarFooterLogo />
    </div>
  );
}
