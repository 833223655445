import "./App.css";
import { Sidebar } from "./components/sidebar/Sidebar.js";
import { Chat } from "./components/chat/Chat.js";
import { Modals } from "./components/modals/Modals.js";
import { useState, StrictMode } from "react";

function App(props) {
  // define state variables for the backdrop
  const [modals, setModals] = useState({
    fileUpload: false,
    cloud: false,
    webscan: false,
    cms: false,
    help: false,
    settings: false,
    loading: false,
  });

  const handleClose = () => {
    setModals({
      fileUpload: false,
      cloud: false,
      webscan: false,
      cms: false,
      help: false,
      settings: false,
      loading: false,
    });
  };

  return (
    <div className="App">
      <StrictMode>
        <Sidebar modals={modals} setModals={setModals} />
        <Chat />
        <Modals modals={modals} handleClose={handleClose} />
      </StrictMode>
    </div>
  );
}

export default App;
