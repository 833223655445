import {
  addDoc,
  arrayUnion,
  collection,
  doc,
  getDocs,
  onSnapshot,
  orderBy,
  query,
  updateDoc,
} from "firebase/firestore";
import { useEffect, useState } from "react";
import { db, useUserAuth } from "../../utils/firebaseUtils";
import "./Chat.css";

export function Chat() {
  const { user } = useUserAuth();
  const [session_id, setSessionId] = useState(null);
  const [message, setMessage] = useState("");
  const [isInputDisabled, setIsInputDisabled] = useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();

    if (message === "") {
      // log error
      console.log("Input field is empty");
      return;
    }
    saveMessage(message, session_id, user.uid);
    setMessage("");
    setIsInputDisabled(true);
  };
  const handleChange = (event) => {
    setMessage(event.target.value);
  };

  // useEffect to create a session
  useEffect(() => {
    createSession(user.uid, setSessionId);
  }, [user]);

  return (
    <div className="chat-window-container">
      <div className="chat-window-container-center">
        <div className="chat-container">
          {session_id && user.uid ? (
            <Messages
              session_id={session_id}
              uid={user.uid}
              inputDisableFunction={setIsInputDisabled}
            />
          ) : (
            null
          )}
          <form className="input-window" onSubmit={handleSubmit}>
            <input
              id="chat-text-input"
              className="chat-text-input"
              name="chat-window-message"
              type="text"
              onChange={handleChange}
              value={message}
              autoComplete="off"
              autoFocus
              disabled={isInputDisabled}
            />
            <button
              className="chat-send-btn"
              type="submit"
              disabled={isInputDisabled}
            >
              Send
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}

function createSession(uid, setSessionId) {
  // if uid is null or undefined, return
  if (uid === null || uid === undefined) {
    return;
  }
  console.log(uid);
  const sessionRef = collection(db, "sessions", uid, "sessions");
  const q = query(sessionRef, orderBy("date", "desc"));
  getDocs(q).then((querySnapshot) => {
    // User has no sessions
    if (querySnapshot.size === 0) {
      // Create a new session
      addDoc(sessionRef, {
        date: new Date(),
      }).then((docRef) => {
        console.log("No sessions found - created a new session " + docRef.id);
        // update session_id var to the new session id
        setSessionId(docRef.id);
      });
    } else {
      // sort querySnapshot by date field
      querySnapshot.docs.sort((a, b) => {
        return a.data().date.toDate() - b.data().date.toDate();
      });

      // Check if the last session is older than 1 hour

      const lastSession = querySnapshot.docs[0];

      const lastSessionDate = lastSession.data().date;
      const currentDate = new Date();
      const diff = currentDate - lastSessionDate.toDate();
      const diffHours = Math.floor(diff / 1000 / 60 / 60);
      if (diffHours > 1) {
        // Create a new session
        addDoc(sessionRef, {
          date: new Date(),
        }).then((docRef) => {
          console.log(
            "No recent Session - Created a new session  " + docRef.id
          );

          // update session_id var to the new session id
          setSessionId(docRef.id);
        });
      } else {
        // Use the last session
        console.log("Using the last session" + lastSession.id);
        // update session_id var to the new session id
        setSessionId(lastSession.id);
      }
    }
  });
}

// function to get all messages in the current session
function Messages(props) {
  // set messages to state
  const [messages, setMessages] = useState([]);
  console.log("Messages component mounted");
  console.log(props);
  useEffect(() => {
    // log when component mounts
    console.log("Messages component called useEffect");
    const unsubscribe = getMessages(
      props.session_id,
      props.uid,
      setMessages,
      props.inputDisableFunction
    );
    // function that call unsubscribe and log when component unmounts
    return () =>  unsubscribe();

  }, [props.session_id, props.uid, props.inputDisableFunction]);
  // return each message in the messages array between divs
  return (
    <ul id="chat-thread" className="chat-thread">
      {messages.map((message, index) => (
        <li key={index}>{message.text}</li>
      ))}
    </ul>
  );
}

function getMessages(session_id, uid, setMessages, inputDisableFunction) {
  // if session_id is null or undefined, return
  if (session_id === null || session_id === undefined || session_id === "") {
    // return empty unsubscribe function
    return () => {};
  }
  // if uid is null or undefined, return
  if (uid === null || uid === undefined) {
    // return empty unsubscribe function
    return () => {};
  }

  console.log("Getting messages for session " + session_id);
  // get the session document
  const sessionRef = doc(db, "sessions", uid, "sessions", session_id);
  const unsubscribe = onSnapshot(sessionRef, (doc) => {
    // log
    console.log("snapshot");
    var array = doc.data().messages;
    // iterate through the messages array and display them if array is not empty
    if (array !== undefined) {
      setMessages(array);
      // check last message source if bot
      if (array[array.length - 1].source === "bot") {
        // enable input
        console.log("enabling input");
        inputDisableFunction(false);
      }
    }
  });
  return unsubscribe;
}

function saveMessage(message, session_id, uid) {
  // if session_id is null or undefined, return
  if (session_id === null || session_id === undefined || session_id === "") {
    return;
  }
  // if uid is null or undefined, return
  if (uid === null || uid === undefined) {
    return;
  }
  // get the session document
  const sessionRef = doc(db, "sessions", uid, "sessions", session_id);
  // get the messages array
  // log the message
  console.log("Saving message " + message);
  updateDoc(sessionRef, {
    messages: arrayUnion({
      text: message,
      processed: false,
      source: "user",
    }),
  });
}
