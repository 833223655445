import {
  signInWithEmailAndPassword,
  signInWithPopup,
  GoogleAuthProvider,
} from "firebase/auth";
import { auth } from "../../utils/firebaseUtils.js";



const google_provider = new GoogleAuthProvider();

export function HandleLogin() {
  var email = document.getElementById("email").value;
  var password = document.getElementById("password").value;
  signInWithEmailAndPassword(auth, email, password)
    .then((userCredential) => {
      console.log("Success! Welcome back!");
    })
    .catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
      // log error code and message
      console.log(errorCode, errorMessage);
      window.alert("Error occurred. Try again.");
    });
}

export function handleGoogleLogin() {
  signInWithPopup(auth, google_provider)
    .then((result) => {
      // This gives you a Google Access Token. You can use it to access the Google API.
      const credential = GoogleAuthProvider.credentialFromResult(result);
      const token = credential.accessToken;
      // The signed-in user info.
      const user = result.user;
      // Log token and user
      console.log(token, user);
      console.log("Success! Welcome back!");
      // ...
    })
    .catch((error) => {
      // Handle Errors here.
      const errorCode = error.code;
      const errorMessage = error.message;
      // The email of the user's account used.
      const email = error.customData.email;
      // The AuthCredential type that was used.
      const credential = GoogleAuthProvider.credentialFromError(error);
      // Log error code and message and email and credential
      console.log(errorCode, errorMessage, email, credential);
      window.alert("Error occurred. Try again.");

    });
}
