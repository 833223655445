import React from "react";
import App from "./App";
import Login from "./components/login/Login";
import { useUserAuth } from "./utils/firebaseUtils";

const Main = () => {
  // function to update user in the state
  const updateUser = (userObject) => {
    // log message to console
    console.log("User logged in: ", userObject);
    // end of log
    console.log("user");
    this.setState({ user: userObject });
  };

  const { user } = useUserAuth();

  // check if user is logged in
  if (user) {
    return (
      <React.StrictMode>
        <App user={user} />
      </React.StrictMode>
    );
  } else {
    return (
      <React.StrictMode>
        <Login updateUser={updateUser} />
      </React.StrictMode>
    );
  }
};

export default Main;
