import { initializeApp } from "firebase/app";
import { createContext, useContext, useEffect, useState } from "react";
import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  onAuthStateChanged,
  signOut,
  connectAuthEmulator,
} from "firebase/auth";
import {
  getFirestore,
  query,
  collection,
  where,
  onSnapshot,
  connectFirestoreEmulator,
} from "firebase/firestore";
import { getFunctions } from "firebase/functions";
// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyC5B5L6h9oDSx0eptg_MRBYle-cqZcjU04",
  authDomain: "inqr-ai.firebaseapp.com",
  projectId: "inqr-ai",
  storageBucket: "inqr-ai.appspot.com",
  messagingSenderId: "423837537229",
  appId: "1:423837537229:web:b869294a695c0144beaa84",
  measurementId: "G-YS48L5485H",
};

export const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
export const functions =  getFunctions(app,"europe-west3");
// Check if running locally or on cloud
if (
  window.location.hostname === "localhost" ||
  window.location.hostname === "127.0.0.1"
) {
  connectAuthEmulator(auth, "http://localhost:9099");
  connectFirestoreEmulator(db, "localhost", 8080);
}

const userAuthContext = createContext();

export function UserAuthContextProvider({ children }) {
  const [user, setUser] = useState({});

  function logIn(email, password) {
    return signInWithEmailAndPassword(auth, email, password);
  }
  function signUp(email, password) {
    return createUserWithEmailAndPassword(auth, email, password);
  }
  function logOut() {
    return signOut(auth);
  }

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentuser) => {
      setUser(currentuser);
    });

    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <userAuthContext.Provider value={{ user, logIn, signUp, logOut }}>
      {children}
    </userAuthContext.Provider>
  );
}

export function useUserAuth() {
  return useContext(userAuthContext);
}

export function getUserSubscriptions(uid, setsubscription) {
  if (uid === undefined || uid === null) {
    return;
  }
  // listen for user subscriptions in customers/uid/subscriptions onsnapshot
  const docsRef = collection(db, "customers", uid, "subscriptions");
  const q = query(docsRef, where("status", "==", "active"));
  const unsubscribe = onSnapshot(
    q,
    (querySnapshot) => {
      const docList = querySnapshot.docs.map((docSnapshot) => ({
        id: docSnapshot.id,
        data: docSnapshot.data(),
        path: docSnapshot.ref.path,
      }));
      // if docList is empty, setsubscription to null
      if (docList.length === 0) {
        setsubscription(null);
      } else {
        setsubscription(docList[0]);
      }
      

    },
    (error) => console.log(error)
  );
  return unsubscribe;
}
