import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faGear,
  faRightFromBracket,
  faCircleQuestion,
  faFileInvoice,
} from "@fortawesome/free-solid-svg-icons";
import logoWhite from "../../static/logo-white.png";
import { useUserAuth, functions } from "../../utils/firebaseUtils";
import { httpsCallable } from "firebase/functions";

export function SidebarFooter(props) {
  const { logOut } = useUserAuth();

  const handleLogout = async () => {
    try {
      await logOut();
    } catch (error) {
      console.log(error.message);
    }
  };
  return (
    <div>
      <hr className="solid-divider" />
      <div id="sidebar-footer">
        <button id="help-btn" className="a-button" onClick={ 
          () => {
            const modals = { ...props.modals };
            modals["help"] = true;
            props.setModals(modals);
          }
        } >
          <FontAwesomeIcon icon={faCircleQuestion} fixedWidth />
          &nbsp;Help
        </button>

        {props.subscription ? (
          <BillingLink modals={props.modals} setLoading={props.setModals} />
        ) : null}
        <button id="settings-btn" className="a-button" onClick={
          () => {
            const modals = { ...props.modals };
            modals["settings"] = true;
            props.setModals(modals);
          }
        } >
          <FontAwesomeIcon icon={faGear} fixedWidth />
          &nbsp;Settings
        </button>
        <button
          id="sign-out-button"
          onClick={handleLogout}
          className="a-button"
        >
          <FontAwesomeIcon icon={faRightFromBracket} fixedWidth />
          &nbsp;Logout
        </button>
      </div>
    </div>
  );
}

function BillingLink(props) {
  return (
    <button
      className="a-button"
      id="billing-btn"
      onClick={() => {
        const modals = { ...props.modals };
        modals["loading"] = true;
        props.setLoading(modals);
        handleBillingClick();
      }}
    >
      <FontAwesomeIcon icon={faFileInvoice} fixedWidth />
      &nbsp;Billing
    </button>
  );
}

export function SidebarFooterLogo(props) {
  return (
    <div id="footer-logo">
      <img src={logoWhite} alt="logo" />
    </div>
  );
}

async function handleBillingClick() {
  const getLink = httpsCallable(
    functions,
    "ext-firestore-stripe-payments-createPortalLink"
  );
  console.log("window.location");
  getLink({ returnUrl: window.location.origin, locale: "auto" }).then(
    (result) => {
      // Read result of the Cloud Function.
      const url = result.data.url;
      window.location.assign(url);
    }
  );
}

