import "./login.css";
import logoWhite from "../../static/logo-white.png";
import { HandleLogin , handleGoogleLogin } from "./loginUtils.js";

function Login(props) {
    // create a function to handle login
    function handleLogin() {
        HandleLogin(props.updateUser);
        
    } 


  return (

      <div className="screenCenter login-body-all login-body">
        <div className="logo">
          <img className="img-logo" src={logoWhite}  alt="logo" />
        </div>
        <section className="login-section">
          <h1>Login</h1>

          <div className="login-form">
            <h4 className="login-h4">Username</h4>
            <div className="username-input">
              <i className="fas fa-user"></i>
              <input className="login-input username-input"
                type="email"
                id="email"
                name="email"
                required="required"
                placeholder="Type your email"
              />
            </div>
            <h4 className="login-h4">Password</h4>
            <div className="password-input">
              <i className="fas fa-lock"></i>
              <input className="login-input password-input"
                id="password"
                type="password"
                name="password"
                required="required"
                placeholder="Type your password"
              />
            </div>
          </div>
          <button id="login_button" className="login-btn" onClick={handleLogin}>
            LOGIN
          </button>
          <button id="google_button" className="login-btn" onClick={handleGoogleLogin}>
            Sign-in with Google
          </button>
          <div className="alternative-signup">
            <p>Don't have an account?</p>
            <a id="signUPbtn" href="_blank">
              Sign up
            </a>
          </div>
        </section>


      </div>

  );
}

export default Login;
