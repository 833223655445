import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faCheck, faWarning } from "@fortawesome/free-solid-svg-icons";
import "./KBList.css";
import { db } from "../../utils/firebaseUtils";
import {
  collection,
  query,
  onSnapshot,
  deleteDoc,
  doc,
} from "firebase/firestore";
import { useUserAuth } from "../../utils/firebaseUtils";

export function UserKnowledgeDomain(props) {
  //const data = props.domain;

  const [docList, setDocList] = useState([]);
  const [webList, setWebList] = useState([]);
  const [cmsList, setCMSList] = useState([]);
  const [cloudList, setCloudList] = useState([]);

  const { user } = useUserAuth();

  useEffect(() => {
    return streamUserDocuments("file", user.uid, setDocList);
  }, [user]);
  useEffect(() => {
    return streamUserDocuments("website", user.uid, setWebList);
  }, [user]);
  useEffect(() => {
    return streamUserDocuments("cms", user.uid, setCMSList);
  }, [user]);
  useEffect(() => {
    return streamUserDocuments("cloud", user.uid, setCloudList);
  }, [user]);

  const userKD = {
    files: docList,
    clouds: cloudList,
    websites: webList,
    cms: cmsList,
  };
  return (
    <div className="userKnowledgeDomain">
      <div className="sidebar-title">Your Knowledge Domain</div>
      <div id="item-list">
        <ListType data={userKD} />
      </div>
      <hr className="solid-divider" />
    </div>
  );
}

function ListType(props) {
  return (
    <ul id="list" className="parent-list">
      {Object.keys(props.data).map((sublist) => (
        <li key={sublist} className="doc-list">
          <div className="doc-list-div">
            <input type="checkbox" className="kb-item-checkbox" />
            {sublist}
          </div>

          <ul>
            {props.data[sublist].map((item) => (
              <ListItem
                key={item.id}
                item={item.data}
                onDelete={() => handleDelete(item.path)}
              />
            ))}
          </ul>
        </li>
      ))}
    </ul>
  );
}

function ListItem(props) {
  return (
    <li>
      <div className="kb-item-list-container">
        <input type="checkbox" className="kb-item-checkbox" />
        <div className="kb-item-div">
          {props.item.name}
          <div>
            {props.item.embedded ? (
              <FontAwesomeIcon className="kb-item-btn kb-item-embedded" icon={faCheck} />
            ) : (
              <FontAwesomeIcon className="kb-item-btn kb-item-not-embedded" icon={faWarning} />
            )}
            <button
              className="kb-item-delete-btn kb-item-btn"
              onClick={props.onDelete}
            >
              <FontAwesomeIcon icon={faTrash} />
            </button>
          </div>
        </div>
      </div>
    </li>
  );
}

function streamUserDocuments(type, uid, setDocList) {
  // if uid is undefined or null return
  if (uid === undefined || uid === null) {
    return;
  }

  const q = query(collection(db, "kb", uid, type));
  const unsubscribe = onSnapshot(
    q,
    (querySnapshot) => {
      const docList = querySnapshot.docs.map((docSnapshot) => ({
        id: docSnapshot.id,
        data: docSnapshot.data(),
        path: docSnapshot.ref.path,
      }));
      setDocList(docList);
    },
    (error) => console.log(error)
  );
  return unsubscribe;
}

function handleDelete(path) {
  // delete file from firestore using deleteDoc
  deleteDoc(doc(db, path));
}
