import { useUserAuth } from "../../utils/firebaseUtils";
import { faBolt } from "@fortawesome/free-solid-svg-icons";
import profilePicture from "./profilePicture.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {startPayment} from "../../utils/stripeUtils";
import { db } from "../../utils/firebaseUtils";
import { CircularProgress, Box, Typography } from "@mui/material";
// Header component
export function SidebarHeader(props) {
  const { user } = useUserAuth();




  return (
    <div>
      <div id="user-info">
        <div id="user-photo">
          <img
            id="user-photo-img"
            src={getProfilePicture(user.photoURL)}
            alt="user"
          />
        </div>
        <div id="user-name">
          <h3 id="user-display-name">{user.displayName}</h3>
          <div id="user-class">
            {props.subscription && props.subscription.data.status === "active" ? (
              <div className="sidebar-header-paid-status-container">
              <UserPlan status={props.subscription.data.status}/> 
              {/* <UserCredit /> */}
              </div>
            ) : (
              <UserPlan status="free" uid={props.uid} setLoading={props.setLoading} modals={props.modals}/>
            )
            }
            
          </div>
        </div>
      </div>

      <hr className="solid-divider" />
    </div>
  );
}

function getProfilePicture(profilePicUrl) {
  // check if profilePic is null or empty string or undefined
  if (
    profilePicUrl === null ||
    profilePicUrl === "" ||
    profilePicUrl === undefined
  ) {
    return profilePicture;
  } else {
    return profilePicUrl;
  }
}

function UserPlan(props) {
console.log(props);
  if (props.status === "free") {
    return (
      <button id="upgrade-plan-btn" className="plan-text" onClick={
        () => {
          const modals = {...props.modals};
          modals["loading"]= true;
          props.setLoading(modals);
          startPayment(db,props.uid);
        }
      }>
        <FontAwesomeIcon icon={faBolt} className="gold-text" /> Upgrade Plan
      </button>
    );
  } else if(props.status === "active") {
    return (

        <span id="current-plan-btn" className="plan-text" href="_blank">
          <FontAwesomeIcon icon={faBolt} className="gold-text" /> Serious User
        </span>

    );
  }
}

function UserCredit(props) {
  const now = 60;
  return (
    <div id="user-credit">
      <CircularProgressWithLabel value={now} />
    </div>
  );
}


function CircularProgressWithLabel(props) {
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress color="success" variant="determinate" {...props} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography variant="caption" component="div" color="white">
          {`${Math.round(props.value)}%`}
        </Typography>
      </Box>
    </Box>
  );
}
