import {
  Backdrop,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@mui/material";
import logoDropbox from "./logo_dropbox.png";
import logoGoogleDrive from "./logo_google_drive.png";
import logoICloud from "./logo_icloud.png";
import logoZotero from "./logo_zotero.png";
import "./modals.css";

export function Modals(props) {
  return (
    <div>
      <FileUploadModal
        open={props.modals.fileUpload}
        handleClose={props.handleClose}
      />
      <CloudConnectModal
        open={props.modals.cloud}
        handleClose={props.handleClose}
      />
      <WebScanModal
        open={props.modals.webscan}
        handleClose={props.handleClose}
      />
      <CMSModal open={props.modals.cms} handleClose={props.handleClose} />
      <HelpModal open={props.modals.help} handleClose={props.handleClose} />
      <SettingsModal
        open={props.modals.settings}
        handleClose={props.handleClose}
      />
      <LoadingModal
        open={props.modals.loading}
        handleClose={props.handleClose}
      />
    </div>
  );
}

function FileUploadModal(props) {
  return (
    <Dialog
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{"Upload your files"}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Let Google help apps determine location. This means sending anonymous
          location data to Google, even when no apps are running.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleClose} autoFocus>
          Done
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function CloudConnectModal(props) {
  return (
    <Dialog
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth={true}
      maxWidth="lg"
    >
      <DialogTitle id="alert-dialog-title">
        {"Connect your cloud accounts"}
      </DialogTitle>

      <DialogContent>
        <div className="modal-grid-column">
          <div className="modal-grid-row">
            <div
              className="modal-grid-item modal-grid-item-enabled"
              onClick={() => {
                addCloudAccount("dropbox");
              }}
            >
              <img src={logoDropbox} className="modal-grid-img " alt="dropbox" />
            </div>

            <div
              className="modal-grid-item"
              onClick={() => {
                addCloudAccount("googledrive");
              }}
            >
              <img src={logoGoogleDrive} className="modal-grid-img" alt="gdrive"/>
            </div>
          </div>
          <div className="modal-grid-row">
            <div
              className="modal-grid-item"
              onClick={() => {
                addCloudAccount("icloud");
              }}
            >
              <img src={logoICloud} className="modal-grid-img" alt="icloud" />
            </div>

            <div
              className="modal-grid-item"
              onClick={() => {
                addCloudAccount("zotero");
              }}
            >
              <img src={logoZotero} className="modal-grid-img" alt="zotero"/>
            </div>
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleClose} autoFocus>
          Done
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function WebScanModal(props) {
  return (
    <Dialog
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{"Scan a website"}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Let Google help apps determine location. This means sending anonymous
          location data to Google, even when no apps are running.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleClose} autoFocus>
          Done
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function CMSModal(props) {
  return (
    <Dialog
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {"Connect your CMS / SaaS accounts"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Let Google help apps determine location. This means sending anonymous
          location data to Google, even when no apps are running.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleClose} autoFocus>
          Done
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function HelpModal(props) {
  return (
    <Dialog
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{"Help & Support"}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Let Google help apps determine location. This means sending anonymous
          location data to Google, even when no apps are running.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleClose} autoFocus>
          Done
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function SettingsModal(props) {
  return (
    <Dialog
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{"Settings"}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Let Google help apps determine location. This means sending anonymous
          location data to Google, even when no apps are running.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleClose} autoFocus>
          Done
        </Button>
      </DialogActions>
    </Dialog>
  );
}
function LoadingModal(props) {
  return (
    <Backdrop
      sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={props.open}
      onClick={props.handleClose}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  );
}

function addCloudAccount(cloud) {
  console.log("addCloudAccount", cloud);
  // display alert
  alert(`addCloudAccount ${cloud}`);
}
