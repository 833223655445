import { collection, addDoc, onSnapshot} from "firebase/firestore";
// start a stripe payment
export async function startPayment(db, uid) {

    console.log("starting payment");
    // add document to customers/uid/checkout_sessions collection
    const docRef = await addDoc(collection(db, "customers", uid, "checkout_sessions"), {
      price: "price_1MQtoEJpCjuEZ4DqpcXv5gnr",
      success_url: window.location.href,
      cancel_url: window.location.href,
    })
    
  
    onSnapshot(docRef, (snap) => {
      const { error, url } = snap.data();
      if (error) {
        // Show an error to your customer and
        // inspect your Cloud Function logs in the Firebase console.
        alert(`An error occured: ${error.message}`);
      }
      if (url) {
        // We have a Stripe Checkout URL, let's redirect.
        window.location.assign(url);
      }
    });
  }
  