import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFile,
  faCloudArrowDown,
  faGlobe,
  faArrowRightArrowLeft,
} from "@fortawesome/free-solid-svg-icons";

export function AddKnowledgeDomain(props) {
  return (
    <div id="sidebar-kb-add">
      <div className="sidebar-title">Add Knowledge</div>
      <div className="grid-container">
        <div
          id="grid-file-btn"
          className="grid-item"
          onClick={() => {
            props.setModals({ ...props.modals, fileUpload: true });
          }}
        >
          <FontAwesomeIcon icon={faFile} />
        </div>
        <div
          id="grid-cloud-btn"
          className="grid-item"
          onClick={() => {
            props.setModals({ ...props.modals, cloud: true });
          }}
        >
          <FontAwesomeIcon icon={faCloudArrowDown} />
        </div>
        <div
          id="grid-web-btn"
          className="grid-item"
          onClick={() => {
            props.setModals({ ...props.modals, webscan: true });
          }}
        >
          <FontAwesomeIcon icon={faGlobe} />
        </div>
        <div
          id="grid-cms-btn"
          className="grid-item"
          onClick={() => {
            props.setModals({ ...props.modals, cms: true });
          }}
        >
          <FontAwesomeIcon icon={faArrowRightArrowLeft} />
        </div>
      </div>
    </div>
  );
}
